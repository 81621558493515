export const CONSTANTS_MEASURES = {
    PADDING_HORIZONTAL: 30,

    INPUT_LABEL_MARGIN: 3,
    EXTRA_SMALL_MARGIN: 5,
    SMALL_MARGIN: 10,
    NORMAL_MARGIN: 20,
    LARGE_MARGIN: 30,
    EXTRA_LARGE_MARGIN: 30,

    PADDING_SMALL: 5,
    PADDING_NORMAL: 10,
    PADDING_LARGE: 15,
    PADDING_XL: 20,
    PADDING_2XL: 40,
    PADDING_DROPDOWN: 8,

    NORMAL_RADIUS: 10,

    FONT_SIZE_EXTRA_SMALL: 12,
    FONT_SIZE_SMALL: 15,
    FONT_SIZE_MEDIUM: 17,
    FONT_SIZE_MEDIUM_LARGE: 19,
    FONT_SIZE_LARGE: 22,
    FONT_SIZE_EXTRA_LARGE: 25,
    FONT_SIZE_XL: 30,
    FONT_SIZE_2XL: 58,
    FONT_SIZE_3XL: 78,

    ICON_HEADER: 25,

    OPACITY_BUTTON: 0.8,
    OPACITY_DISABLED_BUTTON: 1,

    MAX_Z_INDEX: 10,

    CLASS: {
        FONT_SIZE_EXTRA_SMALL: "text-xs",
        FONT_SIZE_SMALL: "text-sm",
        FONT_SIZE_MEDIUM: "text-base",
        FONT_SIZE_LARGE: "text-lg",
        FONT_SIZE_XL: "text-xl",
        FONT_SIZE_2XL: "text-2xl",
        FONT_SIZE_3XL: "text-3xl",
        FONT_SIZE_4XL: "text-4xl",
        FONT_SIZE_5XL: "text-5xl",
    }
};

export const CONSTANTS_COLORS = {
    COLORS: {
        BACKGROUND_LIGHT: "#F6F6F6",
        BACKGROUND_DARK: "#1F2937",
        BACKGROUND_HEADER_DARK: "#161d27",
        BACKGROUND_DANGER: "rgba(232, 55, 17, 0.4)",
        BACKGROUND_SUCCESS: "rgba(40, 167, 69, 0.4)",
        BACKGROUND_SHADOW: "rgba(0, 0, 0, 0.6)",

        TEXT_DARK: "#1F2937",
        TEXT_LIGHT: "#F9F9F9",

        PLACEHOLDER_TEXT_DARK: "#D3D3D3",
        PLACEHOLDER_TEXT_LIGHT: "#F9F9F9",

        PRIMARY_COLOR: "#FACC15",
        PRIMARY_COLOR_DARK: "rgb(250, 204, 21, 0.55)",
        PRIMARY_DARK_COLOR: "#EAB308",

        TRANSPARENT: "transparent",
        WHITE_COLOR: "white",
        BLACK_COLOR: "black",
        GRAY_EXTRA_LIGHT_COLOR: "#EDEDED",
        GRAY_LIGHT_COLOR: "#C5C5C5",
        GRAY_COLOR: "#808080",
        GRAY_DARK_COLOR: "#525252",
        LINK_BLUE_COLOR: "#3388FF",

        DANGER_COLOR: "#E83711",
        WARNING_COLOR: "#FFC107",
        SUCCESS_COLOR: "#28A745",

        INHERIT: "inherit",
    },
    CLASS: {
        PRIMARY_COLOR: "text-yellow-400",

        GRAY_EXTRA_LIGHT: "text-gray-300",
        GRAY_LIGHT: "text-gray-400",
        GRAY: "text-gray-500",
        GRAY_DARK: "text-gray-600",
        GRAY_EXTRA_DARK: "text-gray-800"
    }
};
